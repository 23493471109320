import { createContext } from 'react';
import * as yup from 'yup';
import { validationSchema as manageFieldsValidationSchema } from './Manage/constants';

export const TABS = {
  CREATIVE_LIBRARY: 'creativeLibrary',
  UPLOAD_ASSET: 'uploadAsset',
  VAST_TAG: 'vastTag',
  MANAGE: 'manage',
};

export const creativeTabs = {
  [TABS.CREATIVE_LIBRARY]: {
    label: 'Creative Library',
  },
  [TABS.UPLOAD_ASSET]: {
    label: 'Upload Video',
  },
  [TABS.VAST_TAG]: {
    label: 'By VAST Tag',
  },
  [TABS.MANAGE]: {
    label: 'Manage',
  },
};

export const staticDisplayCreativeTabs = {
  [TABS.CREATIVE_LIBRARY]: {
    label: 'Static Display Creative Library',
  },
  [TABS.UPLOAD_ASSET]: {
    label: 'Upload Display',
  },
  [TABS.MANAGE]: {
    label: 'Manage',
  },
};

export const CreativeContext = createContext({
  tab: TABS.CREATIVE_LIBRARY,
  setTab: () => {},
  library: [],
  drop: () => {},
  toggleAssignCreative: () => {},
});

export const CreativeUploadContext = createContext({
  cancelUploading: async () => {},
  updateCreative: async () => {},
  createFile: async () => {},
  createVastTags: async () => {},
  drop: async () => {},
  getAdditionalCreativeInfo: async () => {},
});

export const MAX_TITLE_LENGTH = 191;

export const CREATIVE_WARNINGS = {
  maxLength: `The Creative Title is longer than ${MAX_TITLE_LENGTH} characters and may appear cut-off`,
};

export const rotationTexts = {
  random:
    'With Random Delivery the creative will be served at random, which translates to approximately even distribution',
  weighted:
    'Weighted Delivery allows you to determine the max allocation of the ad group budget that can go to each creative by assigning a value between 1 and 100 (%) to determine the relative weight with which the creative will be served',
};

export const fields = {
  weightingRotation: {
    path: 'creative_weighting_method',
    defaultValue: false,
  },
  files: {
    path: 'files',
    defaultValue: [],
    rule: yup
      .array()
      .of(
        yup.object().shape({
          click_url: yup.string().url('Please provide a valid URL'),
        }),
      )
      .test(
        'unique',
        'Some files uploading is in progress',
        files => !files.some(file => file.progress !== 100),
      ),
  },
  search: {
    path: 'search',
    defaultValue: '',
  },
  creatives: {
    path: 'creatives',
    defaultValue: [],
  },
  draftCreatives: {
    path: 'draftCreatives',
    defaultValue: [],
    rule: yup.array().of(manageFieldsValidationSchema),
  },
  vastTags: {
    path: 'vastTags',
    defaultValue: [
      {
        name: '',
        vast_tag_url: '',
      },
    ],
    rule: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('Title is required'),
          vast_tag_url: yup
            .string()
            .required('VAST Tag URL is required')
            .matches(
              /^https:\/\/[^/]+/,
              'The VAST Tag URL is not configured correctly',
            ),
        }),
      )
      .min(1),
  },
};

export const creativesSchema = yup.object().shape({
  [fields.files.path]: fields.files.rule,
  [fields.creatives.path]: yup.array(),
  [fields.weightingRotation.path]: yup.boolean(),
  [fields.search.path]: yup.string(),
  [fields.vastTags.path]: fields.vastTags.rule,
  [fields.draftCreatives.path]: fields.draftCreatives.rule,
});

export const weightingRotationOptions = {
  random: 'RANDOM',
  weighted: 'WEIGHTED',
};
