import React from 'react';
import {
  Avatar,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { Hint } from '../../../ui/Hint';
import { HighlightedContent } from '../../Advanced';
import { fromHighlightedContent } from '../../Advanced/utils';
import { AdvancedBox } from '../AdvancedBox';
import {
  AvatarImageStyled,
  CellContentStyled,
  CellContentVirtualizedStyled,
  ReactiveIconAlert,
  ReactiveIconSmall,
  RecommendedAlertStyled,
  RecommendedChipStyled,
  TableCellAdditionalDataBlockStyled,
} from '../styles';
import { inventoryType } from '../types';
import { AdvancedTable } from './AdvancedTable';
import { useAdvancedCustomInventoryTable } from './hooks/useAdvancedCustomInventoryTable';
import {
  customInventoryTabValues,
  inventoryCategoryName,
} from '../constants';
import { values } from 'lodash';
import {
  TableGroupCellStyled,
  TableGroupCellStyledVirtualized,
} from '../../Advanced/styles';
import { IncludeExcludeSwitch } from '@v2/components/ui/Switch';

const Cell = ({ data, virtualized, ...props }) => {
  const CellContentComponent = virtualized
    ? CellContentVirtualizedStyled
    : CellContentStyled;
  return (
    <CellContentComponent
      featured={data.featured}
      direction="row"
      color="chart.6"
      spacing={2}
      {...props}
    >
      <Avatar
        variant="square"
        sx={{
          bgcolor: data.icon?.url ? 'transparent' : 'grey.8',
        }}
      >
        {data.icon?.url ? (
          <AvatarImageStyled alt="" src={data.icon?.url} />
        ) : (
          <span />
        )}
      </Avatar>
      <Stack>
        <Stack direction="row" alignItems="center" spacing={1.25}>
          <HighlightedContent data={data.display_name} />
          <Hint color="grey.6">
            {fromHighlightedContent(data.display_name)}
          </Hint>
          {data.recommended && (
            <RecommendedChipStyled
              icon={<ReactiveIconSmall name="reactive" />}
              label="Recommended"
              size="small"
            />
          )}
        </Stack>
        <Stack>
          <TableCellAdditionalDataBlockStyled>
            {data.info}
          </TableCellAdditionalDataBlockStyled>
        </Stack>
      </Stack>
    </CellContentComponent>
  );
};

const LeftGroup = ({ data }) => {
  return (
    <Typography variant="caption" color="grey.1">
      {data.groupName}
    </Typography>
  );
};

const RightGroup = ({ data, virtualized, onCheck }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="caption" color="grey.1">
        {data.groupName}
      </Typography>
      {values(inventoryCategoryName).includes(data.groupName) && (
        <Stack>
          <IncludeExcludeSwitch
            disabled={
              data.groupName ===
              inventoryCategoryName[customInventoryTabValues.dealId]
            }
            checked={virtualized ? data[1]?.included : data.data[0]?.included}
            onChange={v =>
              onCheck({
                groupName: virtualized ? data[0].groupName : data.groupName,
                value: v.target.checked,
              })
            }
          />
        </Stack>
      )}
    </Stack>
  );
};

const Group = ({ data, type, onCheck, virtualized, ...props }) => {
  const GroupComponent = virtualized
    ? TableGroupCellStyledVirtualized
    : TableGroupCellStyled;

  const additionalProps = virtualized ? {} : { colSpan: 3 };

  return (
    <GroupComponent {...additionalProps} {...props}>
      {type === 'left' ? (
        <LeftGroup data={data} />
      ) : (
        <RightGroup data={data} onCheck={onCheck} />
      )}
    </GroupComponent>
  );
};

LeftGroup.propTypes = {
  data: PropTypes.object,
};

RightGroup.propTypes = {
  data: PropTypes.object,
  onCheck: PropTypes.func,
  virtualized: PropTypes.bool,
};

Cell.propTypes = {
  ...Stack.propTypes,
  data: inventoryType,
  virtualized: PropTypes.bool,
};

Group.propTypes = {
  ...Stack.propTypes,
  virtualized: PropTypes.bool,
  data: PropTypes.object,
  type: PropTypes.string,
  onCheck: PropTypes.func,
};

export const AdvancedCustomInventoryTable = ({
  campaign,
  onClose,
  onSubmit,
  data,
  virtualized = false,
  ...props
}) => {
  const {
    tab,
    tabs,
    selected,
    form,
    legend,
    transform,
    filter,
    data: passedData,
    showRecommendedAlert,
    viewRecommended,
    setSelected,
    checkGroup,
    setFilter,
    setTab,
    includeAll,
    exludeAll,
    toggleViewRecommended,
  } = useAdvancedCustomInventoryTable({ campaign, data });

  return (
    <AdvancedBox
      onClose={onClose}
      header={
        <Typography
          data-testid="custom-inventory-header"
          variant="h3"
        >
          Custom Inventory
        </Typography>
      }
      {...props}
    >
      {showRecommendedAlert && (
        <RecommendedAlertStyled
          action={(
            <FormControlLabel
              control={
                <IncludeExcludeSwitch
                  checked={viewRecommended}
                  onChange={toggleViewRecommended}
                />
              }
              label="View recommended"
            />
          )}
          icon={<ReactiveIconAlert name="reactive" />}
        >
          <strong>Check out your recommended channels!</strong>{' '}
          Advertisers like you have seen a 12-15% lift in ROAS targeting these.
        </RecommendedAlertStyled>
      )}
      <AdvancedTable
        {...props}
        onClose={onClose}
        data={passedData}
        onSubmit={onSubmit}
        selected={selected}
        onIncludeAll={includeAll}
        onExcludeAll={exludeAll}
        transform={{
          left: transform.left,
          right: transform.right,
        }}
        renderGroup={({ data, type, ...props }) => (
          <Group
            data={data}
            onCheck={checkGroup}
            virtualized={virtualized}
            type={type}
            {...props}
          />
        )}
        form={form}
        selectedTab={tab}
        onChangeTab={setTab}
        tabs={tabs}
        filter={filter}
        onFilter={setFilter}
        renderCell={v => <Cell virtualized={virtualized} data={v} />}
        onChange={setSelected}
        legend={legend}
        virtualized={virtualized}
        formProps={showRecommendedAlert ? { style: {
          maxHeight: 'calc(100% - 56px)',
        }} : undefined}
      />
    </AdvancedBox>
  );
};

AdvancedCustomInventoryTable.propTypes = {
  ...AdvancedBox.propTypes,
  data: PropTypes.object,
  virtualized: PropTypes.bool,
};
