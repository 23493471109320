import useSWRMutation from 'swr/mutation';
import useSWR, { useSWRConfig } from 'swr';

import { useCurrentSession } from '../currentSession';
import useSWRInfinite from 'swr/infinite';
import { useMemo } from 'react';
import { getStatus } from '../../containers/CampaignsPage/utils';

const buildCampaignCacheKey = (campaignId, currentAdvertiser, params = {}) => {
  const url = `/campaigns/${campaignId}/`;

  return { url, advertiser: currentAdvertiser.id ?? '-', ...params };
};

export const useGetCampaign = campaignId => {
  const { get, apiIsReady, currentAdvertiser } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    get(url, { params }).then(res => res.data);

  const swr = useSWR(
    apiIsReady && campaignId
      ? buildCampaignCacheKey(campaignId, currentAdvertiser)
      : null,
    fetcher,
  );

  const { data, error, isLoading } = swr;

  return {
    campaign: data,
    error,
    isLoading,
  };
};

export const usePostCampaign = () => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { update: updateCampaignsCache } = useGetCampaigns();
  const { mutate } = useSWRConfig();

  const postCampaign = ({ url }, { arg }) =>
    post(url, arg).then(res => res.data);

  const url = '/campaigns/';

  const options = {
    onSuccess: (newCampaign) => {
      const key = buildCampaignCacheKey(newCampaign.id, currentAdvertiser);
      updateCampaignsCache(newCampaign, true);
      mutate(key, newCampaign, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? { url } : null,
    postCampaign,
    options,
  );

  return { trigger, isMutating };
};

export const usePatchCampaign = campaignId => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { update: updateCampaignsCache } = useGetCampaigns();
  const { mutate } = useSWRConfig();

  const updateCampaign = ({ url }, { arg }) =>
    patch(url, arg).then(res => res.data);

  const url = `/campaigns/${campaignId}/`;

  const options = {
    onSuccess: (updatedCampaign) => {
      const key = buildCampaignCacheKey(
        updatedCampaign.id,
        currentAdvertiser,
      );
      updateCampaignsCache(updatedCampaign);
      mutate(key, updatedCampaign, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? { url } : null,
    updateCampaign,
    options,
  );

  return { trigger, isMutating };
};

export const useGetCampaigns = options => {
  const { getV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const url = '/campaigns/';

  const { data, error, isLoading, isValidating, setSize, size, mutate } =
    useSWRInfinite(
      (index, previousPageData) => {
        if (!apiIsReady || (previousPageData && !previousPageData.next))
          return null;

        return {
          url,
          advertiser: currentAdvertiser.id ?? '-',
          params: {
            sync: 1,
            page_size: 25,
            ...(index && { page: index + 1 }),
          },
        };
      },
      ({ url, params }) => {
        return getV1(url, params).then(res => res.data);
      },
      {
        ...options,
      },
    );

  const items = useMemo(
    () =>
      data
        ? [].concat(
            ...data.map(
              page =>
                page?.results?.map(campaign => {
                  return {
                    ...campaign,
                    status: getStatus(campaign.active, campaign.draft),
                  };
                }) ?? [],
            ),
          )
        : [],
    [data],
  );

  const totalItems = data?.[0]?.count;

  // Remove a campaign from the cache (for when we perform a delete)
  const remove = campaignId => {
    if (campaignId) {
      return mutate(
        prevData => {
          return prevData.map(page => {
            return {
              ...page,
              results: page.results?.filter(
                campaign => campaignId !== campaign.id,
              ),
            };
          });
        },
        { revalidate: false },
      );
    }
  };

  // Patch a campaign into the existing cache.  If we find the campaign in the cache, we updated it,
  // Otherwise we invalidate the data and reload
  const update = (campaign, revalidate = false) => {
    if (campaign) {
      return mutate(
        prevData => {
          if (prevData) {
            let found = false;

            const updatedData = prevData.map(page => {
              return {
                ...page,
                results:
                  page.results?.map(result => {
                    if (result.id === campaign.id) {
                      found = true;
                      return { ...result, ...campaign };
                    }
                    return result;
                  }) ?? null,
              };
            });

            // If we didn't find the campaign, we need to insert the new campaign into the results
            if (!found && prevData) {
              return prevData.map((page, index) => {
                if (index === 0) {
                  return { ...page, results: [campaign, ...page.results] };
                }
                return page;
              });
            }

            return updatedData;
          }
        },
        { revalidate },
      );
    }
  };

  return {
    items,
    data,
    setSize,
    size,
    error,
    isLoading,
    isValidating,
    mutate,
    update,
    remove,
    hasMore:
      totalItems === undefined ||
      (totalItems > 0 && items?.length < totalItems),
  };
};
