import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useLibrary } from './hooks/useLibrary';
import { AdvancedBox } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedBox';
import { AdvancedBoxFooter } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedBoxFooter';
import {
  Button,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { LinkStyled } from '../../../styles';
import { Controller } from 'react-hook-form';
import { fields, TABS } from '../constants';
import { Edit } from '../Upload/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { TableRowGeneral } from './TableRowGeneral';
import clsx from 'clsx';
import { tableOptions } from './constants';

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: theme.spacing(41.25),
  minWidth: theme.spacing(20),
  width: '100%',

  '& .MuiOutlinedInput-root': {
    height: theme.spacing(4.25),

    '& input': {
      padding: theme.spacing(2),
      height: '100%',
      boxSizing: 'border-box',
      color: theme.palette.text.primary,
    },

    '& fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`,
    },

    '&:hover fieldset': {
      borderColor: `${theme.palette.grey['9']} !important`,
    },

    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.blue['6']} !important`,
    },

    '&.Mui-error fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },

  '.MuiInputBase-input': {
    color: theme.palette.grey['5'],
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },

  '.MuiInputAdornment-root': {
    marginLeft: 0,
  },
}));

// Define the styled component for the search icon
const StyledSearchIcon = styled(SearchOutlined)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  color: theme.palette.grey['2'],
}));

// Define the styled component for the clear icon (FontAwesome "x" icon)
const StyledClearIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  color: theme.palette.grey[6],
  cursor: 'pointer',
}));

const TableStyled = styled(Table)(({ theme }) => ({
  '.sticky-left': {
    left: 0,
  },
  '.sticky-right': {
    right: 0,
  },
  thead: {
    zIndex: 4,
    tr: {
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      '.sticky': {
        backgroundColor: theme.palette.common.white,
        zIndex: 3,
      },
      top: 0,
      zIndex: 4,
    },
  },
  'tbody tr': {
    backgroundColor: theme.palette.common.white,
    '.sticky': {
      zIndex: 3,
      backgroundColor: theme.palette.common.white,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[12],
      '.sticky': {
        backgroundColor: theme.palette.grey[12],
      },
    },
    td: {
      wordBreak: 'break-word',
      p: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      },
    },
  },
  'tbody, thead': {
    '.sticky': {
      position: 'sticky',
    },
  },
}));

const CreativeWrapperStyled = styled(Stack)(() => ({
  flex: 1,
  overflowY: 'auto',
}));

export const Library = ({ onClose, onSubmit, isDisplay, info, ...props }) => {
  const {
    library,
    columns,
    setTab,
    sort,
    drop,
    order,
    orderBy,
    creatives,
    cancel,
    showDropzone,
    control,
    search,
    setSearch,
  } = useLibrary({ onClose });

  const [searchValue, setSearchValue] = useState('');

  return (
    <AdvancedBox
      {...props}
      info={info.info}
      onClose={onClose}
      header={
        <Stack direction="row" alignItems="center">
          <Stack sx={{ minWidth: theme => theme.spacing(32.75), maxWidth: theme => theme.spacing(32.75) }}>
            <Typography data-testid="creatives-list-or-upload-screen-header" variant="h3">
              Step 1:{' '}
              {info.select}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={4} sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
            {!showDropzone && (
              <Controller
                control={control}
                name={fields.search.path}
                render={({ field }) => (
                  <SearchFieldStyled
                    {...field}
                    value={searchValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchValue(value); // Update local state
                      setSearch(value); // Update the search state in useLibrary to filter the table
                    }}
                    placeholder={isDisplay ? 'Search displays' : 'Search videos'}
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'creatives-search-input',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue ? (
                            <StyledClearIcon
                              icon={faTimes}
                              onClick={() => {
                                setSearchValue('');
                                setSearch('');
                              }}
                            />
                          ) : (
                            <StyledSearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
            <LinkStyled
              underline="hover"
              onClick={() => setTab(TABS.UPLOAD_ASSET)}
              sx={{ minWidth: theme => theme.spacing(16.875) }}
              data-testid="upload-video-button"
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              {info.upload}
            </LinkStyled>
            {!isDisplay && (
              <LinkStyled
                underline="hover"
                onClick={() => setTab(TABS.VAST_TAG)}
                sx={{ minWidth: theme => theme.spacing(16.875) }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add by VAST Tag
              </LinkStyled>
            )}
          </Stack>
        </Stack>
      }
    >
      <CreativeWrapperStyled flex={1} spacing={5}>
        <Stack
          flex={1}
          spacing={4.25}
        >
          <TableStyled>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: 0,
                  }}
                />
                {columns.map((column) => (
                  <TableCell
                    className={clsx({
                      sticky:
                        tableOptions.sticky.left.includes(column.id) ||
                        tableOptions.sticky.right.includes(column.id),
                      'sticky-left': tableOptions.sticky.left.includes(
                        column.id,
                      ),
                      'sticky-right': tableOptions.sticky.right.includes(
                        column.id,
                      ),
                    })}
                    sx={column.headerSx}
                    key={column.id}
                  >
                    {tableOptions.sortable.includes(column.id) ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={(e) => {
                          sort(e, column.id);
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {library.map((row) => (
                <TableRowGeneral
                  key={row.id}
                  item={row}
                  columns={columns}
                  search={search} // Use the search value from useLibrary
                />
              ))}
            </TableBody>
          </TableStyled>
          {!showDropzone && search && !library?.length && (
            <Stack flex={1} alignItems="center" justifyContent="center">
              <Typography data-testid="creative-search-no-match-message" variant="body2">
                Your search - <b>{search}</b> - did not match any results
              </Typography>
            </Stack>
          )}
          {showDropzone && (
            <Edit flex={1} isDisplay={isDisplay} onDrop={drop}>
              <Typography data-testid="no-creatives-available-message" variant="body2">No creatives in library</Typography>
            </Edit>
          )}
        </Stack>
      </CreativeWrapperStyled>
      <AdvancedBoxFooter>
        <Button
          onClick={cancel}
          color="secondary"
          variant="text"
        >
          Cancel
        </Button>
        <Button
          disabled={!creatives?.length}
          onClick={onSubmit}
          variant="contained"
          data-testid="next-manage-creative"
        >
          Next: Manage Creative
        </Button>
      </AdvancedBoxFooter>
    </AdvancedBox>
  );
};

Library.propTypes = {
  ...AdvancedBox.propTypes,
  isDisplay: PropTypes.bool,
  info: PropTypes.object,
};
