/* eslint-disable no-debugger */
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Box,
  Typography,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import AdvertiserContext from '../AdvertiserContext';
import CampaignFooter from '../CampaignFooter';
import GeoAutoSuggest from '../GeoAutoSuggest';
import Title from '../Title';
import { useAPI } from '../hooks/api';
import { useGeo } from '../hooks/geo';
import { useLoader } from '../hooks/loader';
import { useSaveExit } from '../hooks/saveExit';
import { Themes } from '../../constants';

import GeoTargetsList from './GeoTargetsList';

const PREFIX = 'GeoTargeting';

const classes = {
  divider: `${PREFIX}-divider`,
  paper: `${PREFIX}-paper`,
  switch: `${PREFIX}-switch`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#e0e0e0',
  },

  [`& .${classes.paper}`]: {
    padding: `1.625rem`,
  },

  [`& .${classes.switch}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

const Copies = {
  [Themes.DEFAULT]: {
    HEAD: 'Set geo-targeting',
    INTRO: null,
    BATCH_UPLOAD: 'Batch Upload Geo-targeting',
    TARGET_ENTIRE_US_SWITCH: 'Target Entire US',
    BACK: 'Initiate Campaign',
    US: 'US',
  },
  [Themes.NBCU]: {
    HEAD: 'Set Geotargeting',
    INTRO: 'Enter the location(s) you wish to target. To target the entire U.S., ensure the toggle is on. To exclude locations, type a location, click the icon next to the location name, and select exclude.',
    BATCH_UPLOAD: 'Batch Upload Geotargeting',
    TARGET_ENTIRE_US_SWITCH: 'Target Entire U.S.',
    BACK: 'Create Campaign',
    US: 'U.S.',
  },
};

/////////////////////////////
// GEO TARGETING COMPONENT
/////////////////////////////
const GeoTargeting = props => {

  const history = useHistory();

  const { useGet, usePatch } = useAPI();
  const { geoUrl, formatGeo } = useGeo();
  const { saveProgress } = useSaveExit();
  const { isLoading, setIsLoading } = useLoader();
  const adContext = useContext(AdvertiserContext);

  const {
    isEditing = false,
    combinedTargetingData,
    geoResults,
    setGeoResults,
    targetEntireUS,
    setTargetEntireUS,
    targetGroupName,
    save,
    triggerSave,
    showOverlay,
  } = props;
  const [searchResults, setSearchResults] = useState([]);

  const Copy = useMemo(() => Copies[adContext.theme], [adContext.theme]);

  useEffect(() => {
    saveProgress(save, 'GeoTargeting', handleSave, triggerSave);
  }, [save]);

  const handleSubmit = suggestion => {
    let found = false;

    // Handle Case for user searching for united states
    if (suggestion.id === "country.14135384517372290") {
      setTargetEntireUS(true);
      return
    }

    _.some(geoResults, result => {
      if (
        _.includes(result, suggestion.code) ||
        _.includes(result, suggestion.id)
      ) {
        found = true;
      }
    });

    if (found) {
      return;
    }

    setGeoResults(prev => {
      return [
        ...prev,
        {
          ...suggestion,
          blacklist: false,
        },
      ];
    });

  };
  // Event Handlers
  const handleSearchSubmit = event => {
    const { type } = event;

    if (searchResults.length !== 0 && type === `click`) {
      handleSubmit(searchResults[0]);
    }
  };

  const handleMBSearch = value => {
    const url = geoUrl(value);

    return useGet(url)
      .then(response => {
        if (response && response.features) {
          return response.features;
        }

        return response;
      })
      .catch(error => console.warn('error', error));
  };

  const handleSave = async () => {
    if (!props?.campaignUrl) {
      console.error('Campaign URL is missing');
      return;
    }

    const data = {
      campaign: props.campaignUrl,
      creatives: [],
      daily_budget: 0,
      draft: true,
      targeting: JSON.stringify({
        ...combinedTargetingData,
        geo: formatGeo(geoResults),
      }),
    };

    try {
      const response = await usePatch(
        `/lineitems/${props?.currentAdGroup?.id}/`,
        data
      );

      if (response?.data?.id && !props?.currentAdGroup?.id) {
        props.setCurrentAdGroup(response.data);
      }


      if (save?.step === 'GeoTargeting') {
        if (save.exit) {
          history.push('/');
        }
      }

      if (!save?.exit) {
        props.setStep('DemoTargeting');
        props.updateBreadcrumbs('targeting', 25);
      }

      return response;
    } catch (error) {
      console.error('Error saving GeoTargeting:', error);
      return error;
    }
  };

  return (
    <Root>
      <Grid container style={{ height: '100%' }} justifyContent="center">
        <Box width="100%">
          {!isEditing && (
            <Fragment>
              <Title id="geo-targeting-header">{Copy.HEAD}</Title>

              {Copy.INTRO && <p>{Copy.INTRO}</p>}

              <Divider className={classes.divider} />
            </Fragment>
          )}

          <Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <Grid item xs={isEditing ? 5 : 6}>
              {!isEditing && (
                <FormControlLabel
                  className={classes.switch}
                  data-testid="target-entire-us-switch"
                  label={Copy.TARGET_ENTIRE_US_SWITCH}
                  control={
                    <Switch
                      checked={targetEntireUS}
                      onChange={event =>
                        setTargetEntireUS(event.target.checked)
                      }
                      color="secondary"
                      name="targetEntireUs"
                      size="small"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                />
              )}
              <Paper
                className={classes.paper}
                elevation={0}
                variant="outlined"
              >
                <Grid container>
                  <GeoTargetsList
                    geoResults={geoResults}
                    setGeoResults={setGeoResults}
                    setTargetEntireUS={setTargetEntireUS}
                    copy={Copy}
                    data-testid="panel-geo-target-list"
                  />

                  <Grid item xs={12}>
                    <GeoAutoSuggest
                      label="Geographic Targeting"
                      placeholder="Enter City, Zip Code, DMA, or State"
                      data-testid="geo-targeting-input"
                      value={targetGroupName}
                      handler={handleSubmit}
                      handleSubmitClick={handleSearchSubmit}
                      onSearch={v => handleMBSearch(v)}
                      setSearchResults={setSearchResults}
                    />
                  </Grid>

                  <Grid container item justifyContent="space-between" xs={12}>
                    <Grid item>
                      <Button
                        onClick={() => showOverlay(true)}
                        color="secondary"
                        size="small"
                        startIcon={<CloudUploadOutlinedIcon />}
                        data-testid="batch-upload-btn"
                      >
                        {Copy.BATCH_UPLOAD}
                      </Button>
                    </Grid>
                    {/* TODO: Uncomment to hook up geo imports/exports */}
                    {/* <Grid item>
                    <Button
                      color="secondary"
                      size="small"
                      startIcon={<SystemUpdateAltIcon />}
                    >
                      Download Template
                    </Button>
                  </Grid> */}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {!isEditing && (
          <CampaignFooter
            isLoading={isLoading}
            back={Copy.BACK}
            next={'Demographics'}
            onBack={() => {
              props.setStep('CreateNewCampaign');
              props.handleCampaignPanel(false);
              props.updateBreadcrumbs('targeting', -1, 'createCampaign', 25);
            }}
            onNext={() => {
              setIsLoading(true);
              triggerSave('GeoTargeting', false, 'DemoTargeting');
            }}
            page={1}
          />
        )}

        {isEditing && (
          <Grid container justifyContent="center">
            <Grid item xs={5}>
              <FormControlLabel
                className={classes.switch}
                label={
                  <Typography variant="body2">{Copy.TARGET_ENTIRE_US_SWITCH}</Typography>
                }
                control={
                  <Switch
                    checked={targetEntireUS}
                    onChange={event =>
                      setTargetEntireUS(event.target.checked)
                    }
                    color="secondary"
                    name="targetEntireUs"
                    size="small"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Root>
  );
};

GeoTargeting.propTypes = {
  campaignUrl: PropTypes.string,
  combinedTargetingData: PropTypes.object,
  currentAdGroup: PropTypes.object,
  geoResults: PropTypes.array,
  handleCampaignPanel: PropTypes.func,
  isEditing: PropTypes.bool,
  save: PropTypes.object,
  setCurrentAdGroup: PropTypes.func,
  setGeoResults: PropTypes.func,
  setStep: PropTypes.func,
  setTargetEntireUS: PropTypes.func,
  showOverlay: PropTypes.func,
  targetEntireUS: PropTypes.bool,
  targetGroupName: PropTypes.string,
  triggerSave: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
};

export default GeoTargeting;
