import { useContext } from 'react';
import FlagsContext from '../../providers/FlagsContext';
import { Flags } from '../../constants';

export interface FlagsType {
  [key: string]: boolean;
}

export interface FlagsContextType {
  flags: FlagsType;
  isFlagsLoading: boolean;
}

declare module '../../providers/FlagsContext' {
  export interface FlagsContextValue extends FlagsContextType {}
}

export const useFlags = () => {
  const flagsContext = useContext(FlagsContext) as unknown as FlagsContextType | null;

  if (flagsContext === null) {
    throw new Error('useFlags was used outside a FlagsContextProvider');
  }
  const { flags, isFlagsLoading } = flagsContext;

  return { flags, Flags, isFlagsLoading };
};
