const PAUSED = 1;
const DRAFT = 2;
const ACTIVE = 3;

export const StatusKeys = [
  PAUSED,
  DRAFT,
  ACTIVE,
];

export const Statuses = {
  PAUSED,
  DRAFT,
  ACTIVE,
};

export const StatusNames = {
  [PAUSED]: 'Paused',
  [DRAFT]: 'Draft',
  [ACTIVE]: 'Active',
};

export const StatusList = [
  {
    name: StatusNames[Statuses.PAUSED],
    value: Statuses.PAUSED,
  },
  {
    name: StatusNames[Statuses.DRAFT],
    value: Statuses.DRAFT,
  },
  {
    name: StatusNames[Statuses.ACTIVE],
    value: Statuses.ACTIVE,
  },
];
