import {
  customInventoryTabValues,
  defaultGeoValue,
  inventoryCategoryName,
} from '@v2/components/campaign/CampaignAdGroupSection/constants';
import {
  denormalizeGeoItems,
  distinctNames,
  normalizeGeoItems,
} from '@v2/components/campaign/GeoTargeter';
import { groupBy, isEmpty, map, omit, without } from 'lodash';
import moment from 'moment';
import { fields } from './formConfig';
import { convertToStatus, removeNullValues } from '../utils';
import { targetingFields } from './constants';

export const parseTargeting = passedTargeting => {
  const targeting = passedTargeting ?? '{}';

  try {
    return JSON.parse(targeting);
  } catch {
    return {};
  }
};

export const transformGeography = geo =>
  Object.entries(geo).reduce(
    (acc, [, value]) => [
      ...acc,
      ...value.map(v => {
        return {
          ...v,
        };
      }),
    ],
    []
  );

export const mapAppBundleListToDataItem = (
  item,
  type,
  additionalParams = {}
) => {
  return {
    id: item.id,
    value: item.id,
    key: item.name,
    display_name: item.name,
    type,
    ...additionalParams,
  };
};

export const mapBundleTypeToDataItem = (
  item,
  type,
  additionalParams = {}
) => {
  return {
    id: item,
    value: item,
    key: item,
    display_name: item,
    type,
    ...additionalParams,
  };
};

export const mapInventoryItemToDataItem = (
  item,
  recommendedInventory = []
) => ({
  ...item,
  recommended: recommendedInventory.includes(item.id),
});

export const toEST = date => moment(date).tz('America/New_York');

export const prepareBundles = ({
  bundles,
  availableBundles,
  appBundleList,
  app_name,
  deal_id,
  app_bundle_list,
}) => {
  let bundleEntries = availableBundles.filter(
    ({ bundle_name, ott }) => bundles.includes(bundle_name) && !ott
  );

  if (app_name.values?.length > 0) {
    bundleEntries = [
      ...bundleEntries,
      ...app_name.values.map(name =>
        mapBundleTypeToDataItem(
          name,
          inventoryCategoryName[customInventoryTabValues.appName],
          {
            included: !app_name.blacklist,
          }
        )
      ),
    ];
  }
  if (deal_id.values?.length > 0) {
    bundleEntries = [
      ...bundleEntries,
      ...deal_id.values.map(name =>
        mapBundleTypeToDataItem(
          name,
          inventoryCategoryName[customInventoryTabValues.dealId],
          {
            included: false,
          }
        )
      ),
    ];
  }

  if (app_bundle_list.values?.length > 0) {
    bundleEntries = [
      ...bundleEntries,
      ...app_bundle_list.values.map(name => {
        const actualBundleItem = appBundleList.find(({ id }) => id === name);
        const value = actualBundleItem ?? name;
        const fnc = actualBundleItem
          ? mapAppBundleListToDataItem
          : mapBundleTypeToDataItem;

        return fnc(
          value,
          inventoryCategoryName[customInventoryTabValues.appBundleList],
          {
            included: !app_bundle_list.blacklist,
          }
        );
      }),
    ];
  }

  return bundleEntries;
};

export const prepareOsDeviceSelected = ({
  os = { blacklist: false, ids: [] },
  device = { blacklist: false, ids: [] },
  allowedTargeting = [],
}) => {
  const grouped = groupBy(allowedTargeting, 'type');

  const targetingOsKeys = map(grouped.OS, 'key');
  const targetingDeviceKeys = map(grouped.DEVICE, 'key');

  const osSelected = os?.blacklist
    ? without(targetingOsKeys, ...os.ids)
    : os.ids ?? [];

  const deviceSelected = device?.blacklist
    ? without(targetingDeviceKeys, ...device.ids)
    : device.ids ?? [];

  return {
    os: {
      blacklist: os.blacklist ?? false,
      ids: osSelected,
    },
    device: {
      blacklist: device.blacklist ?? false,
      ids: deviceSelected,
    },
  };
};

export const prepareOsDeviceForSave = ({ os, device, allowedTargeting }) => {
  const grouped = groupBy(allowedTargeting, 'type');

  const targetingOsKeys = map(grouped.OS, 'key');
  const targetingDeviceKeys = map(grouped.DEVICE, 'key');

  const selectedOs = targetingOsKeys.filter(key => os.ids.includes(key));

  const selectedDevice = targetingDeviceKeys.filter(key =>
    device.ids.includes(key)
  );

  return {
    os: {
      blacklist: os.blacklist ?? false,
      ids: isEmpty(selectedOs) ? targetingOsKeys : selectedOs,
    },
    device: {
      blacklist: device.blacklist ?? false,
      ids: isEmpty(selectedDevice) ? targetingDeviceKeys : selectedDevice,
    },
  };
};

export const mapAudiences = ({ segments = [], allowedAudiences = [] }) =>
  segments.reduce((acc, curr) => {
    const foundAudience = allowedAudiences.find(
      audience => audience.audience_name === curr
    );
    if (foundAudience) acc.push(foundAudience);
    return acc;
  }, []);

export const transformTargetingFields = (
  targetingFields,
  {
    appBundleList = [],
    allowedTargeting = [],
    availableBundles = [],
    allowedAudiences = [],
  }
) => {
  const {
    app_name = {},
    deal_id = {},
    app_bundle_list = {},
    bundles = [],
    geo = defaultGeoValue,
    dayparting = [],
    segments = [],
    os = {},
    device = {},
    gender = fields.social.defaultValue.gender,
    age = fields.social.defaultValue.age,
    income = fields.social.defaultValue.income,
    inventory,
  } = parseTargeting(targetingFields ?? '{}');

  console.log({
    n: normalizeGeoItems(geo),
  });

  return {
    [fields.advancedCustomInventory.path]: prepareBundles({
      appBundleList,
      app_name,
      deal_id,
      app_bundle_list,
      bundles,
      availableBundles,
    }),
    [fields.geography.path]: transformGeography(normalizeGeoItems(geo)),
    [fields.advancedAudience.path]: mapAudiences({
      segments,
      allowedAudiences,
    }),
    [fields.social.path]: {
      gender,
      age,
      income,
    },
    [fields.inventoryOption.path]: inventory,
    [fields.advancedInventory.path]: prepareOsDeviceSelected({
      os,
      device,
      allowedTargeting,
    }),
    [fields.configureAdGroupDaypartingEnabled.path]: dayparting?.length > 0,
    [fields.configureAdGroupDayparting.path]: dayparting,
  };
};

export const transformFreqCapFields = adGroup => {
  return {
    [fields.configureAdGroupFrequencyCapEnabled.path]:
      adGroup?.freq_caps?.length > 0,
    [fields.configureAdGroupFrequencyCap.path]: adGroup?.freq_caps,
  };
};

export const transformAdGroupToFields = (
  adGroup,
  {
    appBundleList = [],
    audiences = [],
    customInventory = [],
    library = [],
    allowedTargeting = [],
    campaign = {},
  }
) => {
  const {
    targeting,
    creatives = [],
    start_date,
    end_date,
    cpm,
    bid_strategy,
    daily_budget,
    draft,
    ...rest
  } = adGroup ?? {};

  const transformedFields = {
    ...transformTargetingFields(targeting, {
      allowedTargeting,
      appBundleList,
      availableBundles: customInventory,
      allowedAudiences: audiences,
    }),
    ...transformFreqCapFields(adGroup),
  };

  const nowISO = moment(toEST(moment()).toISOString());

  return {
    ...rest,
    ...transformedFields,
    [fields.draft.path]: 'draft' in adGroup ? draft : campaign?.draft,
    [fields.campaign.path]: campaign?.id,
    [fields.status.path]: convertToStatus(adGroup),
    [fields.adGroupBudget.path]: daily_budget ?? 0,
    [fields.adGroupBidStrategy.path]: bid_strategy,
    [fields.maxCPMBidEnabled.path]: parseFloat(cpm) > 0,
    [fields.maxCPMBid.path]: cpm,
    [fields.startDate.path]: start_date
      ? moment(start_date)
      : moment(campaign.start_date ?? nowISO),
    [fields.endDate.path]:
      end_date || campaign?.end_date
        ? moment(end_date ?? campaign?.end_date)
        : null,
    [fields.startTime.path]: start_date
      ? moment(start_date)
      : campaign.start_date
      ? moment(campaign.start_date)
      : null,
    [fields.endTime.path]:
      end_date || campaign?.end_date
        ? moment(end_date ?? campaign?.end_date)
        : null,
    [fields.creatives.path]: creatives.length
      ? creatives
          .map(creative => library?.find(({ id }) => id === creative))
          .filter(v => v) ?? []
      : [],
  };
};

export const transformToTargeting = (
  formData,
  { allowedTargeting, adGroup }
) => {
  const [
    { os, device },
    social,
    audiences,
    bundles,
    inventory,
    dayparting = [],
    timezone,
    geoFormData,
  ] = map(targetingFields, field => formData[field]);

  const initialTargeting = parseTargeting(adGroup?.targeting);

  const { age, gender, income } = social;

  const groupedGeo = groupBy(
    geoFormData.filter(v => v.type !== distinctNames.empty),
    'type'
  );

  const geo = {
    [distinctNames.countries]: [],
    [distinctNames.region]: [],
    [distinctNames.place]: [],
    [distinctNames.zip]: [],
    [distinctNames.dma]: [],
    ...denormalizeGeoItems(groupedGeo),
  };

  // We need to move geo.country into geo.countries
  if (geo[distinctNames.country]) {
    geo[distinctNames.countries] = [
      ...geo[distinctNames.countries],
      ...geo[distinctNames.country],
    ];
    delete geo[distinctNames.country];
  }

  const app_names = bundles.filter(
    bundle =>
      bundle.type === inventoryCategoryName[customInventoryTabValues.appName]
  );

  const app_bundle_list = bundles.filter(
    bundle =>
      bundle.type ===
      inventoryCategoryName[customInventoryTabValues.appBundleList]
  );

  const deal_id = bundles.filter(
    bundle =>
      bundle.type === inventoryCategoryName[customInventoryTabValues.dealId]
  );

  const preparedOsDevice = prepareOsDeviceForSave({
    os,
    device,
    allowedTargeting,
  });

  const data = removeNullValues({
    geo,
    dayparting,
    ...preparedOsDevice,
    gender,
    ...(isEmpty(age) ? {} : { age }),
    ...(isEmpty(income) ? {} : { income }),
    bundles:
      (inventory === 'bundles' || inventory === 'performanceOptimized') &&
      bundles.length
        ? map(bundles, 'bundle_name').filter(Boolean)
        : null,
    app_name: app_names.length
      ? {
          values: app_names.map(bundle => bundle.value),
          blacklist: !app_names[0].included,
        }
      : initialTargeting.app_name
      ? { values: [] }
      : null,
    app_bundle_list: app_bundle_list.length
      ? {
          values: app_bundle_list.map(bundle => bundle.value),
          blacklist: !app_bundle_list[0].included,
        }
      : initialTargeting.app_bundle_list
      ? { values: [] }
      : null,
    deal_id: deal_id.length
      ? {
          values: deal_id.map(bundle => bundle.value),
          blacklist: true,
        }
      : initialTargeting.deal_id
      ? { values: [] }
      : null,
    segments: map(audiences, 'audience_name'),
    inventory,
    timezone,
  });

  return JSON.stringify(data);
};

export const transformAdGroup = (
  formData,
  { bidStrategies, campaign, allowedTargeting, adGroup }
) => {
  const actualData = omit(formData, [
    fields.advancedCustomInventory.path,
    fields.advancedAudience.path,
    fields.advancedInventory.path,
    fields.maxCPMBidEnabled.path,
    fields.configureAdGroupDaypartingEnabled.path,
    fields.configureAdGroupFrequencyCapEnabled.path,
    fields.configureAdGroupFrequencyCap.path,
    fields.configureAdGroupDayparting.path,
    fields.startTime.path,
    fields.endTime.path,
    fields.geo.path,
    fields.social.path,
    fields.configureAdGroupFrequencyCap.path,
    fields.inventoryOption.path,
    fields.description.path,
    fields.adGroupBudget.path,
    fields.maxCPMBid.path,
    fields.status.path,
    fields.targetCPA.path,
    'adjusted_cpm',
  ]);

  const formBidStrategy = formData[fields.adGroupBidStrategy.path];

  const actualStartTime = formData[fields.startTime.path] ?? moment();

  return removeNullValues({
    ...actualData,
    draft: actualData[fields.draft.path] ?? true,
    description: actualData[fields.description.path],
    cpm:
      formData[fields.maxCPMBid.path] === ''
        ? null
        : formData[fields.maxCPMBid.path],
    freq_caps: formData[fields.configureAdGroupFrequencyCap.path]?.map(v =>
      removeNullValues({
        ...v,
        campaign: campaign?.id ?? null,
      })
    ),
    campaign: campaign?.id,

    daily_budget: Number(formData[fields.adGroupBudget.path]).toFixed(2),
    bid_strategy: formBidStrategy
      ? bidStrategies.find(
          strategy => strategy.id === formData[fields.adGroupBidStrategy.path]
        ).id
      : formBidStrategy,
    start_date: moment(formData[fields.startDate.path])
      .set({
        minutes: actualStartTime.minutes(),
        hours: actualStartTime.hours(),
      })
      .toISOString(),
    end_date: formData[fields.endDate.path]
      ? moment(formData[fields.endDate.path])
          .set({
            minutes: formData[fields.endTime.path].minutes(),
            hours: formData[fields.endTime.path].hours(),
          })
          .toISOString()
      : null,
    creatives: formData.creatives.map(creative => creative.id),
    creative_weighting_method: formData[fields.weightingRotation.path],
    targeting: transformToTargeting(formData, {
      campaign,
      allowedTargeting,
      adGroup,
    }),
    creativesAdditionalData: formData.creatives.map(
      ({ creative, lineitem, weighting, id, name, click_url, pixels }) => ({
        id,
        creative,
        lineitem,
        weighting,
        click_url,
        name,
        pixels,
      })
    ),
  });
};
