import React from 'react';
import moment from 'moment-timezone';
import {
  Box,
  Stack,
  Modal,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
import { ModalWrapper, LineChart } from './components';
import { Tab } from './components/styled';
import { RealTimeModalProps } from './constants';

const RealTimeModal = (props: RealTimeModalProps) => {
  const {
    impressions,
    wins,
    bids,
    winRate,
    data,
    tab = "impressions",
    timeRange = "1h",
    description,
    open,
    onTabChange,
    onTimeRangeChange,
    onClose,
  } = props;

  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="real-time-modal"
    >
      <ModalWrapper sx={{ width: '100%' }}>
        <Grid container sx={{ p: 5, justifyContent: 'space-between' }}>
          <Grid xs>
            <Typography id="modal-modal-title" variant="subtitle1" sx={{ fontWeight: 700 }}>
              Real-time Data
            </Typography>
            <Typography id="modal-modal-description" variant="body1">
              {description}
            </Typography>
          </Grid>

          <Grid xs={1} sx={{ textAlign: 'right' }}>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TabContext value={tab}>
          <TabList
            onChange={onTabChange}
            aria-label='real time data tabs'
            sx={{ px: 5 }}
          >
            <Tab
              value="impressions"
              label={
                <>
                  <Typography variant="body1" component="h2">
                    <Box
                      sx={{
                        display: 'inline-block',
                        verticalAlign: 'text-top',
                        width: 16,
                        height: 16,
                        backgroundColor: 'chart.7',
                      }}
                    />
                    {' '}
                    Real-time Impressions
                  </Typography>

                  <Typography variant="h1" component="h2">
                    {impressions.toLocaleString('en-US')}
                  </Typography>
                </>
              }
            />
            <Tab
              value="winsBids"
              label={
                <>
                  <Typography variant="body1" component="h2">
                    Real-time
                    {' '}
                    <Box
                      sx={{
                        display: 'inline-block',
                        verticalAlign: 'text-top',
                        width: 16,
                        height: 16,
                        backgroundColor: 'chart.2',
                      }}
                    />
                    {' '}
                    Wins /
                    {' '}
                    <Box
                      sx={{
                        display: 'inline-block',
                        verticalAlign: 'text-top',
                        width: 16,
                        height: 16,
                        backgroundColor: 'chart.4',
                      }}
                    />
                    {' '}
                    Bids
                  </Typography>

                  <Stack direction="row" sx={{ alignItems: 'flex-end' }}>
                    <Typography variant="h1" component="h2">
                      {wins}/{bids}
                    </Typography>

                    <Typography variant="h6" component="h2" sx={{ fontWeight: 500 }}>
                      {winRate}%
                    </Typography>
                  </Stack>
                </>
              }
            />

            <Stack
              sx={{
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <ToggleButtonGroup
                exclusive
                value={timeRange}
                onChange={onTimeRangeChange}
                aria-label="chart frequency"
              >
                <ToggleButton value="1h" aria-label="1 hour">
                  1 hour
                </ToggleButton>

                <ToggleButton value="15m" aria-label="15 minutes">
                  15 min
                </ToggleButton>

                <ToggleButton value="1m" aria-label="1 minute">
                  1 min
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </TabList>

          <TabPanel value="impressions" sx={{ padding: 0 }}>
            <LineChart
              dataset={data}
              series={[
                {
                  id: 'Impressions',
                  dataKey: 'impressions',
                  color: theme.palette.chart[7],
                }
              ]}
              xAxis={[{ dataKey: 'date' }]}
              yAxis={[{ dataKey: 'impressions' }]}
            />
          </TabPanel>

          <TabPanel value="winsBids" sx={{ padding: 0 }}>
            <LineChart
              dataset={data}
              series={[
                {
                  id: 'Wins',
                  dataKey: 'wins',
                  color: theme.palette.chart[2],
                },
                {
                  id: 'Bids',
                  dataKey: 'bids',
                  color: theme.palette.chart[4],
                },
              ]}
              xAxis={[{ dataKey: 'date' }]}
              yAxis={[{}]}
            />
          </TabPanel>

          <Box sx={{ p: 5 }}>
            <Typography variant="caption" color="#77858C">
              <FontAwesomeIcon icon={faSquareInfo} />{' '}
              Real-time data through {moment().format('h:mma, MMM D, YYYY')} (will not match Performance Data)
            </Typography>
          </Box>
        </TabContext>
      </ModalWrapper>
    </Modal>
  );
};

export default RealTimeModal;
