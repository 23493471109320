import { useGetAllowedTargeting } from '@apis/allowedTargeting';
import { useGetAllBidStrategies } from '@apis/bidStrategy';
import {
  useGetAllCreatives,
  useGetCreativeLineItemTrigger,
  usePatchCreative,
  usePatchCreativeLineItem,
} from '@apis/creatives';
import {
  useAllDisplays,
  usePatchStaticDisplayCreative,
} from '@apis/displays';
import { GROUP_TYPE, useGroups } from '@apis/groups';
import { useWizardSection } from '@components/Wizard';
import { pick } from 'lodash';
import { useGeneralNavigation } from '../../hooks/useGeneralNavigation';
import { removeEmptyValues, removeNullValues } from '../../utils';
import { transformAdGroup } from '../utils';

export const useGroupSectionSubmit = ({
  isDisplay,
  campaign,
  form,
  sectionKey,
  adGroup,
}) => {
  const { state, toCampaignDetails } = useGeneralNavigation();
  const { data: allowedTargeting } = useGetAllowedTargeting();
  const { data: bidStrategies } = useGetAllBidStrategies();
  const { trigger: updateLineItemCreative, isMutating: isPostMutating } =
    usePatchCreativeLineItem();

  const { invalidate: invalidateCreatives } = useGetAllCreatives({
    disabled: !!isDisplay,
  });
  const { invalidate: invalidateDisplay } = useAllDisplays({
    disabled: !isDisplay,
  });

  const {
    update: updateAdGroup,
    create: createAdGroup,
    isLoading: isAdGroupProcessing,
  } = useGroups(GROUP_TYPE.AD_GROUP, campaign?.id);

  const {
    update: updateStaticDisplayAdGroup,
    create: createStaticDisplayAdGroup,
    isLoading: isStaticDisplayAdGroupProcessing,
  } = useGroups(GROUP_TYPE.STATIC_GROUP, campaign?.id);

  const { trigger: updateCreative } = usePatchCreative();
  const { trigger: getCreativeLineItem } = useGetCreativeLineItemTrigger();

  const { trigger: updateStaticDisplayCreative } =
    usePatchStaticDisplayCreative();

  const { goToNext } = useWizardSection({
    key: sectionKey,
  });

  const handleSubmit = async () => {
    const values = form.getValues();
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      campaign,
      adGroup,
      allowedTargeting,
    });

    const res = await (!adGroup?.temporary
      ? updateAdGroup({ id: adGroup.id, ...rest })
      : createAdGroup(rest));

    const lineItems = await getCreativeLineItem(res.id);

    await Promise.all(
      creativesAdditionalData.map(
        async ({ id, weighting, name, click_url, pixels }) => {
          const updatedData = pick(
            lineItems.find(({ creative }) => creative === id),
            ['id', 'lineitem', 'creative'],
          );

          await updateCreative(
            removeEmptyValues({
              name,
              id: updatedData.creative,
              pixels,
              click_url,
            }),
          );

          return updateLineItemCreative(
            removeNullValues({
              id: updatedData.id,
              creative: updatedData.creative,
              lineitem: updatedData.lineitem,
              weighting,
            }),
          );
        },
      ),
    ).catch(e => console.log(e));

    invalidateCreatives();

    state.singleEdit && campaign.active
      ? toCampaignDetails({ campaign })
      : goToNext({ checkDirty: false });
  };

  const handleStaticDisplaySubmit = async () => {
    const values = form.getValues();
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      campaign,
      adGroup,
    });

    const res = await (!adGroup?.temporary
      ? updateStaticDisplayAdGroup({ id: adGroup.id, ...rest })
      : createStaticDisplayAdGroup({ id: adGroup.id, ...rest }));

    if (res.id) {
      await Promise.all(
        res.creatives.map(async staticDisplayCreativeId => {
          const additionalData = creativesAdditionalData.filter(
            ({ id }) => id === staticDisplayCreativeId,
          )[0];

          await updateStaticDisplayCreative(
            removeNullValues({
              ...additionalData,
              id: staticDisplayCreativeId,
            }),
          );
        }),
      ).catch(e => console.log(e));

      invalidateDisplay();

      state.singleEdit && campaign.active
        ? toCampaignDetails({ campaign })
        : goToNext({ checkDirty: false });
    }
  };

  return {
    submit: isDisplay ? handleStaticDisplaySubmit : handleSubmit,
    mutating:
      isPostMutating ||
      isAdGroupProcessing ||
      isStaticDisplayAdGroupProcessing,
  };
};
