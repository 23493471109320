import { FormProvider, useForm } from 'react-hook-form';

import React, { lazy, useCallback, useEffect, useMemo } from 'react';

import {
  useGetCampaign,
  usePatchCampaign,
  usePostCampaign,
} from '@apis/campaigns';
import { useWizardSection, sectionProps } from '@components/Wizard';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentSession, useFlags } from '@hooks';
import {
  defaultValues,
  validationSchema,
} from '@v2/components/forms/CampaignForm/formConfig';
import { defaults } from 'lodash';
import { getDirtyValues, handleErrors } from '../utils';
import { labels } from './constants';
import {
  parseApiDataToFormValues,
  parseFormValuesToApiData,
} from './panes/CampaignInfoPane/utils';
import { SWRConfig } from 'swr';
import { withCamelCase } from '@swr';
import { fields } from '@v2/components/forms/CampaignForm/constants';
import PropTypes from 'prop-types';
import { useWizardPageContentData } from '@components/containers/WizardPage';
import { wizardGeneralErrorFields } from '../constants';

const CampaignInfoPane = lazy(() => import('./panes/CampaignInfoPane'));
const AdvancedCampaignDeliveryOptionsPane = lazy(
  () => import('./panes/AdvancedCampaignDeliveryOptionsPane'),
);

const CampaignSection = ({
  campaignId,
  sectionKey,
  campaignStaticDisplayAdGroups,
}) => {
  const { currentAdvertiser } = useCurrentSession();
  const { flags, Flags } = useFlags();
  const { campaign } = useGetCampaign(campaignId);
  const objectiveGoalsAllowed =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS];
  const resolver = yupResolver(validationSchema({ objectiveGoalsAllowed }));
  const { updateEntityState, activePane, isActivePane, goToNext, goTo } =
    useWizardSection({
      key: sectionKey,
    });

  const { nextSectionContent } = useWizardPageContentData();

  const { trigger: updateCampaign } = usePatchCampaign(campaignId);
  const { trigger: postCampaign } = usePostCampaign();

  const data = useMemo(
    () => parseApiDataToFormValues(campaign),
    [campaign],
  );

  const methods = useForm({
    mode: 'onBlur',
    values: defaults(data, defaultValues()),
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver,
  });

  const {
    formState: { isDirty, dirtyFields },
    setValue,
  } = methods;

  const handleSubmit = useCallback(
    async values => {
      const formValues = campaignId
        ? getDirtyValues({ dirtyFields, values })
        : {
            ...values,
            advertiser: currentAdvertiser.id,
          };

      const data = parseFormValuesToApiData(formValues, {
        ...values,
        advertiser: currentAdvertiser.id,
      }, campaignId);

      const { id } = campaignId
        ? await updateCampaign(data)
        : await postCampaign(data);

      if (campaignId) {
        goToNext({
          checkDirty: false,
        });
      } else {
        goTo(
          {
            campaignId: id,
            sectionId: 1,
          },
          {
            checkDirty: false,
          },
        );
      }
    },
    [
      campaignId,
      dirtyFields,
      nextSectionContent,
      currentAdvertiser.id,
      goToNext,
      goTo,
    ],
  );

  const handleRetargetDisplayToggleChange = (event, displayPctField) => {
    setValue(fields.displayPct.path, event.target.checked);
    displayPctField.onChange(event.target.checked);
  };

  useEffect(() => {
    updateEntityState({
      dirty: isDirty,
    });
  }, [updateEntityState, isDirty]);

  return (
    <FormProvider {...methods}>
      {(!activePane || isActivePane(labels.nameDates.value)) && (
        <CampaignInfoPane
          onRetargetDisplayToggleChange={handleRetargetDisplayToggleChange}
          campaignStaticDisplayAdGroups={campaignStaticDisplayAdGroups}
          onSubmit={handleErrors(handleSubmit, {
            setError: methods.setError,
            generalErrorFields: [...wizardGeneralErrorFields],
            mappedFields: {
              budget: fields.dailyBudget.path,
              freq_caps: fields.advancedOptions.path,
              objective_goal_event: fields.objectiveGoalEvent.path,
            },
          })}
          campaignId={campaignId}
          sectionKey={sectionKey}
        />
      )}
      {isActivePane(labels.objective.value) && (
        <AdvancedCampaignDeliveryOptionsPane />
      )}
    </FormProvider>
  );
};

const CampaignSectionWrapper = props => {
  return (
    <SWRConfig
      value={parent => ({
        use: [...parent.use, withCamelCase],
      })}
    >
      <CampaignSection {...props} />
    </SWRConfig>
  );
};

CampaignSection.propTypes = {
  campaignStaticDisplayAdGroups: PropTypes.array,
  onRetargetDisplayToggleChange: PropTypes.func,
  ...sectionProps,
};

CampaignSectionWrapper.propTypes = {
  ...CampaignSection.propTypes,
};

export default CampaignSectionWrapper;
