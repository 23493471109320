import React, { useContext, useEffect, useRef, useState } from 'react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { styled } from '@mui/material';
import AdvertiserContext from './AdvertiserContext';
import { useAPI } from './hooks/api';
import PropTypes from 'prop-types';

const IframeContainer = styled('div')(({ theme }) => ({
  aspectRatio: '16 / 9',
  [theme.breakpoints.up('lg')]: {
    height: 500,
    aspectRatio: 'unset',
  },
  '& > iframe': {
    width: '100%',
    height: '100%',
  },
}));

export const EmbedLookerChart = ({ dashboard, ...rest }) => {
  const adContext = useContext(AdvertiserContext);
  const [url, setUrl] = useState(null);
  const containerRef = useRef(null);
  const { useGet } = useAPI();

  useEffect(() => {
    if (!adContext.id) return;

    LookerEmbedSDK.init(process.env.LOOKER_BASE_URL);

    useGet(`/looker-reporting-embed-url/${dashboard}`).then(res => {
      setUrl(res.embedUrl);
    });
  }, [adContext?.id]);

  useEffect(() => {
    if (!url || !containerRef.current) return;

    if (containerRef.current) {
      containerRef.current.innerHTML = '';
    }

    makeDashboard();
  }, [url]);

  const handleSizeChange = event => {
    containerRef.current.style.height = `${event.height}px`;
  };

  const makeDashboard = () => {
    LookerEmbedSDK.createDashboardWithUrl(url)
      .appendTo(containerRef.current)
      .on('page:properties:changed', handleSizeChange)
      .build()
      .connect()
      .catch(error => {
        console.error('An unexpected error occurred', error);
      });
  };

  return <IframeContainer ref={containerRef} {...rest} />;
};

EmbedLookerChart.propTypes = {
  dashboard: PropTypes.string.isRequired,
};
