import { isUndefined } from 'lodash';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { fields as manageFields } from '../Manage/constants';
import { TABS, fields } from '../constants';
import { transformCreativeToImpressionPixelsArray } from '../utils';
import { useUpload } from './useUpload';

export const useAdvancedCreative = ({ onCloseInfo }) => {
  const [tab, setTab] = useState(TABS.CREATIVE_LIBRARY);
  const form = useFormContext();

  const { watch, setValue, getValues } = form;

  const [creatives, files] = watch([
    fields.creatives.path,
    fields.files.path,
  ]);

  const handleInfoClose = tab => () => {
    onCloseInfo(tab);
  };

  const handleToLibrary = () => {
    setTab(TABS.CREATIVE_LIBRARY);
  };

  const handleToManage = () => {
    setValue(
      fields.draftCreatives.path,
      getValues(fields.creatives.path).map(creative => ({
        ...(transformCreativeToImpressionPixelsArray(creative) ?? {}),
        [manageFields.showClickUrl.path]:
          !!creative[manageFields.click_url.path],
      })),
    );
    setTab(TABS.MANAGE);
  };

  const handleToggleAssign = useCallback(
    (item, status) => {
      const isForce = !isUndefined(status);
      const currentCreatives = getValues(fields.creatives.path);
      const creative = item;

      if (isForce && status) {
        setValue(fields.creatives.path, [...currentCreatives, creative]);
        return;
      }

      if (isForce && !status) {
        setValue(
          fields.creatives.path,
          currentCreatives.filter(({ id }) => id !== creative?.id),
        );
        return;
      }

      const creativeRegisteredIndex = currentCreatives.findIndex(
        ({ id }) => id === creative.id,
      );
      const isAssigned = creativeRegisteredIndex > -1;

      if (isAssigned) {
        setValue(fields.creatives.path, [
          ...currentCreatives.slice(0, creativeRegisteredIndex),
          ...currentCreatives.slice(creativeRegisteredIndex + 1),
        ]);
      } else {
        setValue(fields.creatives.path, [...currentCreatives, creative]);
      }
    },
    [getValues, setValue],
  );

  const upload = useUpload({
    toggleAssignCreative: handleToggleAssign,
  });

  return {
    form,
    tab,
    setTab,
    files,
    closeInfo: handleInfoClose,
    creatives,
    toLibrary: handleToLibrary,
    toManage: handleToManage,
    toggleAssignCreative: handleToggleAssign,
    upload,
  };
};
