import { useFormContext } from 'react-hook-form';
import { Copies, fields } from '../constants';
import { useContext } from 'react';
import AdvertiserContext from '@components/AdvertiserContext';
import { useCopy, useDomain } from '@hooks';

export const useBidStrategy = ({ onAdvanced }) => {
  const { setValue, watch, trigger, control } = useFormContext();

  const domain = useDomain();

  const Copy = useCopy(Copies);
  const adContext = useContext(AdvertiserContext);

  const shouldShowBidStrategy =
    adContext?.bidstrategy_set?.length > 0 &&
    adContext.tvsciq &&
    adContext.tvsciq !== 'DISABLED';

  const [bundles, inventoryOption] = watch([
    fields.advancedCustomInventory.path,
    fields.inventoryOption.path,
  ]);

  const [frequencyCap = [], dayparting = [], isMaxCPMBidEnabled] = watch([
    fields.configureAdGroupFrequencyCap.path,
    fields.configureAdGroupDayparting.path,
    fields.maxCPMBidEnabled.path,
  ]);

  const handleAdvanced = (index, type) => {
    setValue(
      fields.configureAdGroupFrequencyCap.path,
      frequencyCap?.map((v, i) => ({
        ...v,
        isEditing: i === index && type === 'frequencyCap',
      })) ?? [],
    );

    setValue(
      fields.configureAdGroupDayparting.path,
      dayparting?.map((v, i) => ({
        ...v,
        isEditing: i === index && type === 'dayparting',
      })) ?? [],
    );

    onAdvanced();
  };

  return {
    setValue,
    control,
    frequencyCap,
    domain,
    Copy,
    trigger,
    adContext,
    dayparting,
    isMaxCPMBidEnabled,
    shouldShowBidStrategy,
    bundles,
    inventoryOption,
    advanced: handleAdvanced,
  };
};
