import { routes } from "@components/Wizard";

export const RoutePaths = {
  WIZARD_SELECTOR: '/wizard-selector',
  CREATE_ACCOUNT: '/create-account',
  ACCOUNT_SETUP: '/account-setup',
  ACCEPT_INVITE: '/accept-invite/:inviteId?',
  JOIN: '/join/:inviteId?',
  CAMPAIGNS: '/campaigns',
  CAMPAIGN_REWORK: routes.campaign,
  OLD_CAMPAIGNS_EDIT: '/campaigns/:campaignId?/adgroups/:adGroupId?',
  OLD_CAMPAIGN_EDIT: '/campaign-edit/:id/',
  OLD_CAMPAIGN_SETUP: '/campaign-setup',
  CREATIVE_DETAIL:
    '/campaigns/:campaignId/adgroups/:adGroupId/creative/:creativeId',
  CREATIVE_INDEX: '/campaigns/:campaignId/adgroups/:adGroupId/creative',
  DISPLAY_DETAIL:
    '/campaigns/:campaignId/adgroups/:adGroupId/displays/:displayId',
  DISPLAY_INDEX: '/campaigns/:campaignId/adgroups/:adGroupId/displays',
  AD_GROUPS: '/campaigns/:campaignId/adgroups',
  CREATIVES: '/creatives',
  DISPLAYS: '/displays',
  TRACKING: '/tracking',
  MEMBER: '/member',
  DASHBOARD: '/dashboard',
  MANAGER: '/manager',
  REPORTS: '/reports',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SETTINGS: '/settings',
  SUPPORT: '/support',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  NBCU_LOGIN_CALLBACK: '/login/callback/nbcu',
  QUIET_LOGIN_CALLBACK: '/login/callback/quiet',
  LOGIN_CALLBACK: '/login/callback',
  HOME: '/',
};
