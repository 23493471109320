import { last } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSWRConfig } from 'swr';
import useSWRInfinite, { unstable_serialize } from 'swr/infinite';
import { useCurrentSession } from '../currentSession';

export const useInfiniteGlobalCacheUpdate = () => {
  const { mutate } = useSWRConfig();

  return {
    trigger: (key, data) => {
      const options = {
        revalidate: false,
        populateCache: true,
      };
      mutate(key, data, options);
      mutate(
        unstable_serialize(() => key),
        data,
        options
      );
    },
  };
};

export const useInfiniteSizer = (data, { isLoading, setSize }) => {
  useEffect(() => {
    if (!isLoading && last(data)?.next) {
      setSize(prev => prev + 1);
    }
  }, [data, isLoading]);
};

export const useInfiniteCacheUpdate = (data, keyGenerator) => {
  const [cacheKeys, setCacheKeys] = useState([]);
  const keyGeneratorRef = useRef(keyGenerator);
  const { mutate: swrMutate } = useSWRConfig();

  useEffect(() => {
    const updatedCacheKeys = [];
    if (data && keyGeneratorRef.current) {
      data.forEach(page => {
        page.results.forEach(element => {
          const key = keyGeneratorRef.current(element);
          swrMutate(key, element, {
            populateCache: true,
          });
          updatedCacheKeys.push(key);
        });
      });
    }
    setCacheKeys(updatedCacheKeys);
  }, [data]);

  useEffect(() => {
    keyGeneratorRef.current = keyGenerator;
  }, [keyGenerator]);

  return {
    cacheKeys,
  };
};

/**
 * @typedef {import('swr/infinite').SWRInfiniteConfiguration} SWRInfiniteConfiguration
 * @typedef {import('swr/infinite').SWRInfiniteConfiguration} SWRInfiniteResponse
 */
/**
 * @param {string} url
 * @param {Object} [options]
 * @param {Function} [options.keyGenerator]
 * @param {Object} [options.params]
 * @param {boolean} [options.params.disabled]
 * @param {boolean} [options.params.v1]
 * @param {SWRInfiniteConfiguration} [mutationOptions]
 * @returns {SWRInfiniteResponse & { items: any[], cacheKeys: string[]}}
 */
export const useLoadInfinite = (url, options = {}, mutationOptions = {}) => {
  const {
    params: { disabled, v1, ...otherParams } = {},
    keyGenerator,
    ignoreAdvertiser,
  } = options;

  const { apiIsReady, currentAdvertiser, get, getV1 } = useCurrentSession();

  const getter = v1 ? getV1 : get;

  const fetcher = ({ url, params }) => {
    // The advertiser param is only used to generate the cache key; it is not
    // needed here in the fetcher. We are simply removing it from the params.
    // eslint-disable-next-line no-unused-vars
    const { advertiser, ...otherParams } = params;
    return getter(url, { ...otherParams }).then(res => res.data);
  };

  const advertiser = currentAdvertiser.id;

  const { data, error, isLoading, isValidating, mutate, setSize, size } =
    useSWRInfinite(
      (pageIndex, previousPageData) => {
        if (disabled || !apiIsReady || (!ignoreAdvertiser && !advertiser))
          return null;

        if (previousPageData && !previousPageData.next) return null;
        return {
          url,
          advertiser,
          params: {
            advertiser,
            page: pageIndex + 1,
            ...otherParams,
          },
        };
      },
      fetcher,
      {
        revalidateFirstPage: false,
        ...mutationOptions,
      }
    );

  useInfiniteSizer(data, { isLoading, setSize });

  return {
    ...useInfiniteCacheUpdate(data, keyGenerator),
    data,
    error,
    isLoading,
    isValidating,
    mutate,
    setSize,
    size,
    items: useMemo(
      () =>
        data
          ? [].concat(
              ...((data?.[0]?.results
                ? data.map(page => page?.results)
                : data) ?? [])
            )
          : [],
      [data]
    ),
  };
};
