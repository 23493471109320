import moment from 'moment-timezone';
import { Statuses } from './statuses';

export const formatEndDate = endDate => {
  if (Date.parse(endDate)) {
    return moment(endDate).format('l');
  }
  return 'No End Date';
};

export const getStatus = (active, draft) => {
  if (draft) {
    return Statuses.DRAFT;
  }

  if (active === true) {
    return Statuses.ACTIVE;
  }

  return Statuses.PAUSED;
};
