import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
import {
  Alert,
  Box,
  Stack,
  TextField,
  styled,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { SelectDate } from '../../ui/SelectDate';
import { SelectTime } from '../../ui/SelectTime';
import { fields, subSections } from './constants';
import { useGeneral } from './hooks/useGeneral';
import { SectionBoxStyled } from './styles';
import { NumberTextField } from '@v2/components/ui/NumberTextField';
import { PropTypes } from 'prop-types';
import { entityStatus } from '../../../../components/CampaignWizard/constants';
import {
  SectionInfo,
  sectionInfoType,
} from '../../../../components/SectionInfo';

const GeneralSectionInnerBoxStyled = styled(SectionBoxStyled)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25),
  }),
);

export const General = ({ info, campaign, isDisplay, ...props }) => {
  const {
    control,
    status,
    endDate,
    minEndDate,
    maxEndDate,
    minStartDate,
    maxStartDate,
    setDefaultTime,
  } = useGeneral({
    campaign,
  });

  return (
    <GeneralSectionInnerBoxStyled spacing={3} {...props}>
      <SectionInfo
        infoBlocks={info}
        sub={subSections.general.nameAndBudget}
      />
      <Stack spacing={3}>
        {isDisplay && (
          <Alert severity="tip" icon={<FontAwesomeIcon icon={faSquareInfo} />}>
            This ad group retargets the exposed audience of the campaign with
            display ads. Too narrow sub-targeting may affect delivery.
          </Alert>
        )}
        <Controller
          name={fields.adGroupName.path}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                helperText={fieldState.error?.message}
                label="Ad Group Name"
                inputProps={{
                  'data-testid': 'ad-group-name'
                }}
              />
            );
          }}
        />
        <Stack direction="row" spacing={3}>
          <Controller
            name={fields.adGroupBudget.path}
            control={control}
            render={({ field, fieldState }) => (
              <NumberTextField
                {...field}
                {...fieldState}
                helperText={fieldState.error?.message}
                sx={{ flex: 1 }}
                label="Ad Group Daily Budget"
              />
            )}
          />
        </Stack>
      </Stack>
      <SectionInfo infoBlocks={info} sub={subSections.general.dates} />
      <Stack direction="row" spacing={3}>
        <Controller
          name={fields.startDate.path}
          control={control}
          render={({ field, fieldState }) => (
            <SelectDate
              {...field}
              onChange={e => {
                field.onChange(e);
                setDefaultTime(e, fields.startTime.path);
              }}
              minDate={minStartDate}
              maxDate={maxStartDate}
              disabled={status === entityStatus.active}
              fieldState={fieldState}
              sx={{ flex: 1 }}
              label="Start Date"
            />
          )}
        />
        <Controller
          name={fields.startTime.path}
          control={control}
          render={({ field, fieldState }) => (
            <SelectTime
              {...field}
              disabled={status === entityStatus.active}
              fieldState={fieldState}
              sx={{ flex: 1 }}
              label="Start Time"
            />
          )}
        />
      </Stack>

      <Stack direction="row" spacing={3}>
        <Controller
          name={fields.endDate.path}
          control={control}
          render={({ field, fieldState }) => (
            <SelectDate
              {...field}
              minDate={minEndDate}
              maxDate={maxEndDate}
              fieldState={fieldState}
              sx={{ flex: 1 }}
              label="End Date"
            />
          )}
        />
        <Controller
          name={fields.endTime.path}
          control={control}
          render={({ field, fieldState }) => (
            <SelectTime
              {...field}
              disabled={!endDate}
              fieldState={fieldState}
              sx={{ flex: 1 }}
              label="End Time"
            />
          )}
        />
      </Stack>
    </GeneralSectionInnerBoxStyled>
  );
};

General.propTypes = {
  ...Box.propTypes,
  info: PropTypes.arrayOf(sectionInfoType),
  campaign: PropTypes.object,
  isDisplay: PropTypes.bool,
};
