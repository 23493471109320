import { styled } from '@mui/material/styles';
import AppHeader from '../../AppHeader';

const PREFIX = 'CampaignsPage';

export const classes = {
  link: `${PREFIX}-link`,
  draft: `${PREFIX}-draft`,
  active: `${PREFIX}-active`,
  divider: `${PREFIX}-divider`,
  paused: `${PREFIX}-paused`,
  fade: `${PREFIX}-fade`,
  font: `${PREFIX}-font`,
  scheduled: `${PREFIX}-scheduled`,
  stats: `${PREFIX}-stats`,
  tableCell: `${PREFIX}-tableCell`,
  height: `${PREFIX}-height`,
  manageBtn: `${PREFIX}-manageBtn`,
  formControl: `${PREFIX}-formControl`,
  menuIcon: `${PREFIX}-menuIcon`,
  container: `${PREFIX}-container`,
  campaignContainerSpacing: `${PREFIX}-campaignContainerSpacing`,
  loader: `${PREFIX}-loader`,
  pageLoader: `${PREFIX}-pageLoader`,
  modal: `${PREFIX}-modal`,
};

export const StyledAppHeader = styled(AppHeader)(
  ({ theme: { breakpoints, spacing } }) => ({
    [`& .${classes.link}`]: {
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: '700',
    },

    [`& .${classes.draft}`]: {
      color: '#EB9D00',
      background: 'rgba(255, 171, 5, 0.25)',
      // fontSize: '0.625rem',
    },

    [`& .${classes.active}`]: {
      color: '#0fbf84',
      background: 'rgba(122, 220, 187, 0.55)',
      // fontSize: '0.625rem',
    },

    [`& .${classes.divider}`]: {
      marginLeft: spacing(1),
      // marginRight: spacing(1),
    },

    [`& .${classes.paused}`]: {
      color: '#ff0000',
      background: 'rgba(255, 0, 0, 0.31)',
      // fontSize: '0.625rem',
    },

    [`& .${classes.fade}`]: {
      position: 'relative',
    },

    [`& .${classes.font}`]: {
      // fontSize: '0.75rem',
    },

    [`& .${classes.scheduled}`]: {
      color: '#1dafff',
      background: 'rgba(29, 175, 255, 0.24)',
    },

    [`& .${classes.stats}`]: {
      fontWeight: '700',
    },

    [`& .${classes.tableCell}`]: {
      // fontSize: '0.75rem',
      minHeight: 81,
    },

    [`& .${classes.height}`]: {
      // fontSize: '0.75rem',
      minHeight: 81,
      height: 81,
    },

    [`& .${classes.manageBtn}`]: {
      fontWeight: 500,
      marginLeft: -8, // aligning based on the visible left edge of the button
    },

    [`& .${classes.formControl}`]: {
      margin: spacing(1),
      minWidth: 120,
    },

    [`& .${classes.menuIcon}`]: {
      marginRight: 12,
    },

    [`& .${classes.container}`]: {
      paddingTop: spacing(5),
      paddingBottom: spacing(4),
      maxWidth: 'none',
    },

    [`& .${classes.campaignContainerSpacing}`]: {
      padding: spacing(2),
      maxWidth: 'none',
    },

    [`& .${classes.loader}`]: {
      height: 110,

      [breakpoints.up('md')]: {
        height: 125,
      },

      [breakpoints.up('lg')]: {
        height: 165,
      },

      [breakpoints.up('xl')]: {
        height: 210,
      },
    },

    [`& .${classes.pageLoader}`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '0',
      left: '0',
      background: 'rgba(255,255,255,0.6)',
    },

    [`& .${classes.modal}`]: {
      overflowY: 'auto',
    },
  }),
);
